import request from "superagent"
import swal from "sweetalert"
export const ANTRAG_EMPFAENGER = "WFL/ANTRAG_EMPFAENGER"
export const ANTRAG_EMPFAENGER_DEFAULT = "WFL/ANTRAG_EMPFAENGER_DEFAULT"
export const ANTRAG_VERTRETER = "WFL/ANTRAG_VERTRETER"
export const ANTRAG_VERTRETER_DEFAULT = "WFL/ANTRAG_VERTRETER_DEFAULT"
export const LOAD_ANTRAG = "WFL/LOAD_ANTRAG"
export const MONATS_SALDO = "WFL/MONATS_SALDO"
export const CREATE_ANTRAG = "WFL/CREATE_ANTRAG"
export const UPDATE_ANTRAG = "WFL/UPDATE_ANTRAG"

export function LoadAntrag(an_nbr) {
  return (dispatch) => {
    request.get(`/api/antrag/${an_nbr}`).end((err, response) => {
      if (!err) {
        dispatch({ type: LOAD_ANTRAG, id: an_nbr, data: response.body })
      }
    })
  }
}

export function UpdateAntrag(MqID, data) {
  return (dispatch) => {
    request
      .put(`/api/antrag/${MqID}`)
      .send(data)
      .end((err, response) => {
        if (!err) {
          dispatch({
            type: UPDATE_ANTRAG,
            an_nbr: data.an_nbr,
            data: response.body
          })

          // TODO hier action vom Kalender neu laden
        }
      })
  }
}

export function UpdateKorrAntrag(MqID, data) {
  return (dispatch) => {
    request
      .put(`/api/korrantrag/${MqID}`)
      .send(data)
      .end((err, response) => {
        if (!err) {
          dispatch({
            type: UPDATE_ANTRAG,
            an_nbr: data.an_nbr,
            data: response.body
          })

          // TODO hier action vom Kalender neu laden
        }
      })
  }
}

export function CreateKorrAntrag(korrantrag) {
  return (dispatch, getState) => {
    //dispatch(AntragMessage("Korrektur-Antrag erfolgreich verschickt  !"))
    request
      .post("/api/korrantrag/create")
      .send(korrantrag)
      .end((err, response) => {
        let message = (response.body && response.body.message) || null
        if (err) {
          swal(
            "Fehler beim erstellen des Antrags!",
            message || "Korrektur Antrag momentan nicht möglich",
            "error"
          )
          return
        } else {
          swal("Korrektur Antrag erfolgreich versendet!", "", "success")
          dispatch({ type: CREATE_ANTRAG, data: response.body })
        }
      })
  }
}

export const CreateAntrag = (antrag) => {
  return (dispatch) => {
    request
      .post("/api/antrag/create")
      .send(antrag)
      .end((err, response) => {
        let message = (response.body && response.body.message) || null
        if (err) {
          swal(
            "Fehler beim erstellen des Antrags!",
            message || "Antrag momentan nicht möglich",
            "error"
          )
          return
        } else {
          if (response.body.msg.length > 0) {
            swal(response.body.msg, "", "success")
          } else {
            swal("Antrag erfolgreich versendet!", "", "success")
          }
          dispatch({ type: CREATE_ANTRAG, data: response.body })
        }
      })
  }
}

export function LoadKorrAntrag(an_nbr) {
  return (dispatch) => {
    request.get(`/api/korrantrag/${an_nbr}`).end((err, response) => {
      if (!err) {
        dispatch({ type: LOAD_ANTRAG, id: an_nbr, data: response.body })
      }
    })
  }
}

export function MonatsSaldo(int_id) {
  return (dispatch) => {
    request.get(`/api/p/${int_id}/saldo/aktuell/`).end((err, response) => {
      if (!err) {
        dispatch({ type: MONATS_SALDO, int_id, data: response.body })
      }
    })
  }
}

export const EmpfaengerDefault = (user_id) => {
  return (dispatch) => {
    request.get(`/api/workflow/${user_id}/empfdefault`).end((err, response) => {
      if (!err) {
        dispatch({
          type: ANTRAG_EMPFAENGER_DEFAULT,
          data: response.body
        })
      }
    })
  }
}

export const AntragEmpfaenger = (int_id) => {
  return (dispatch) => {
    request.get(`/api/p/${int_id}/antragempfaenger`).end((err, response) => {
      if (!err) {
        dispatch({
          type: ANTRAG_EMPFAENGER,
          data: response.body
        })
      }
    })
  }
}

export const VertreterDefault = (user_id) => {
  return (dispatch) => {
    request
      .get(`/api/workflowvertr/${user_id}/vertrdefault`)
      .end((err, response) => {
        if (!err) {
          dispatch({
            type: ANTRAG_VERTRETER_DEFAULT,
            data: response.body
          })
        }
      })
  }
}

export const AntragVertreter = (int_id) => {
  return (dispatch) => {
    request.get(`/api/p/${int_id}/mqueuevertreter`).end((err, response) => {
      if (!err) {
        dispatch({
          type: ANTRAG_VERTRETER,
          data: response.body
        })
      }
    })
  }
}
